export const posts = [
  {
    id: 'post1',
    title: 'Traveling Japan and Korea',
    subheader:
      'Recap and recommendations for traveling Japan and Korea. I traveled both countries for one month with friends from the University of Kansas.',
    author: 'Jet Semrick',
    date: '07-18-2023',
  },
  {
    id: 'post2',
    title: 'Engineering and Debate',
    subheader:
      'STEM fields are complimented by research and speaking skills in policy debate.',
    author: 'Jet Semrick',
    date: '11-23-2024',
  },
]
